export const environment = {
  HOST_URL: 'https://www.gantri.com',
  API_URL: process.env.API_URL,
  CREATE_HUB_URL: process.env.CREATE_HUB_URL,
  ANALYTICS_KEY: process.env.ANALYTICS_KEY,
  SEGMENT_KEY: process.env.SEGMENT_KEY,
  YOTPO_KEY: process.env.YOTPO_KEY,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  GOOGLE_MAPS_API_KEY: 'AIzaSyCGhOrWETkB1-Nrqm9llHYOfMdqR_MR_rQ',
  FACEBOOK_APP_ID: process.env.FACEBOOK_ID,
  APP_STAGE: process.env.APP_STAGE,
  STRIPE_KEY: process.env.STRIPE_KEY,
  AFFIRM_PUBLIC_API_KEY: process.env.AFFIRM_PUBLIC_API_KEY,
  LOG_ROCKET_APP_ID: process.env.LOG_ROCKET_APP_ID,
  FIREBASE_KEY: process.env.FIREBASE_KEY,
  FIREBASE_DOMAIN: process.env.FIREBASE_DOMAIN,
  FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
  FIREBASE_URL: process.env.FIREBASE_URL,
  FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
  IS_PRODUCTION: process.env.APP_STAGE === 'production',
  COOKIES_DOMAIN:
    process.env.APP_STAGE === 'development' ? 'localhost' : '.gantri.com',
};
