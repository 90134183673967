import { DefaultTheme } from 'styled-components/macro';

export type ThemeColor =
  | 'primary'
  | 'black'
  | 'translucentBlack'
  | 'white'
  | 'translucentWhite'
  | 'grey'
  | 'lightGrey'
  | 'paper'
  | 'cream'
  | 'red'
  | 'lightRed'
  | 'gold'
  | 'lightGold'
  | 'green'
  | 'lightGreen'
  | 'opaqueGreen'
  | 'greyGreen'
  | 'blue'
  | 'lightBlue'
  | 'sedona'
  | 'stone'
  | 'sunrise'
  | string;

declare module 'styled-components' {
  export interface FontInfo {
    family: string;
    size: string;
    sizeSmaller: string;
    lineHeight: string;
    lineHeightSmaller: string;
  }

  export interface DefaultTheme {
    colors: {
      primary: string;
      black: string;
      translucentBlack: string;
      white: string;
      translucentWhite: string;
      grey: string;
      lightGrey: string;
      paper: string;
      cream: string;
      translucentCream: string;
      red: string;
      lightRed: string;
      gold: string;
      lightGold: string;
      green: string;
      lightGreen: string;
      opaqueGreen: string;
      greyGreen: string;
      blue: string;
      lightBlue: string;
      canyon: string;
      coral: string;
      carbon: string;
      fog: string;
      forest: string;
      meadow: string;
      midnight: string;
      sand: string;
      sedona: string;
      sky: string;
      snow: string;
      sunrise: string;
    };
    shadow: string;
    border: string;
    borderRadius: string;
    grid: {
      gutter: string;
      gutterSmaller: string;
    };
    spacing: {
      s1: string;
      s2: string;
      s3: string;
      s4: string;
      s5: string;
      s6: string;
    };
    transitions: {
      base: string;
      slow: string;
      turtle: string;
      slowest: string;
    };
    fonts: {
      boldFontFamily: string;
      regularFontFamily: string;
      p1: FontInfo;
      p2: FontInfo;
      p3: FontInfo;
      h1: FontInfo;
      h2: FontInfo;
      h3: FontInfo;
      h4: FontInfo;
      h5: FontInfo;
      label: FontInfo;
      link: FontInfo;
      badge: FontInfo;
    };
    layout: {
      header: {
        height: string;
        heightSmaller: string;
      };
      topMenuHeight: string;
      topMenuHeightSmaller: string;
      maxWidth: string;
    };
  }
}

const colors = {
  primary: '#1b7b76',
  black: '#1c1c1c',
  white: '#ffffff',
  grey: '#8f8c8b',
  lightGrey: '#d6d4d2',
  paper: '#edeceb',
  cream: '#f4f4f4',
  red: '#a34241',
  lightRed: '#f6efee',
  gold: '#b88d4e',
  lightGold: '#f8f6f1',
  green: '#568274',
  lightGreen: '#f1f4f2',
  opaqueGreen: '#4a5c54',
  greyGreen: '#b0b0a6',
  blue: '#1561a3',
  lightBlue: '#eff2f3',
  canyon: '#9c3e38',
  coral: '#e6cbc6',
  carbon: '#1c1c1c',
  fog: '#bfc2c7',
  forest: '#24322b',
  meadow: '#c6cebd',
  midnight: '#3e5063',
  sand: '#dfd8d0',
  sedona: '#663a31',
  sky: '#bccbce',
  snow: '#ffffff',
  stone: '#e8e8e6',
  sunrise: '#fabc3c',
};

const theme: DefaultTheme = {
  colors: {
    ...colors,
    translucentBlack: 'rgba(28, 28, 28, 0.6)',
    translucentWhite: 'rgba(255, 255, 255, 0.7)',
    translucentCream: 'rgba(245, 245, 245, 0.2)',
  },
  shadow: `0 .2rem .6rem rgba(0, 0, 0, 0.12)`,
  border: `1px solid ${colors.lightGrey}`,
  borderRadius: '.6rem',
  grid: {
    gutter: '3rem',
    gutterSmaller: '1.2rem',
  },
  spacing: {
    s1: '1.2rem',
    s2: '2rem',
    s3: '3rem',
    s4: '4rem',
    s5: '6rem',
    s6: '12rem',
  },
  transitions: {
    base: '0.15s ease',
    slow: '0.3s ease',
    turtle: '0.8s ease',
    slowest: '2s ease',
  },
  fonts: {
    boldFontFamily: 'Univers 65 Bold',
    regularFontFamily: 'Univers 55 Roman',
    p1: {
      family: 'Univers 55 Roman',
      size: '1.8rem',
      sizeSmaller: '1.6rem',
      lineHeight: '2.8rem',
      lineHeightSmaller: '2.6rem',
    },
    p2: {
      family: 'Univers 55 Roman',
      size: '1.5rem',
      sizeSmaller: '1.4rem',
      lineHeight: '2.5rem',
      lineHeightSmaller: '2.2rem',
    },
    p3: {
      family: 'Univers 55 Roman',
      size: '1.2rem',
      sizeSmaller: '1.2rem',
      lineHeight: '2rem',
      lineHeightSmaller: '2rem',
    },
    h1: {
      family: 'Univers 65 Bold',
      size: '6rem',
      sizeSmaller: '3.6rem',
      lineHeight: '8rem',
      lineHeightSmaller: '4.8rem',
    },
    h2: {
      family: 'Univers 65 Bold',
      size: '3.6rem',
      sizeSmaller: '2.8rem',
      lineHeight: '4.8rem',
      lineHeightSmaller: '3.6rem',
    },
    h3: {
      family: 'Univers 65 Bold',
      size: '2.4rem',
      sizeSmaller: '2rem',
      lineHeight: '3.6rem',
      lineHeightSmaller: '3rem',
    },
    h4: {
      family: 'Univers 65 Bold',
      size: '1.8rem',
      sizeSmaller: '1.6rem',
      lineHeight: '2.8rem',
      lineHeightSmaller: '2.6rem',
    },
    h5: {
      family: 'Univers 65 Bold',
      size: '1.2rem',
      sizeSmaller: '1.2rem',
      lineHeight: '2rem',
      lineHeightSmaller: '2rem',
    },
    label: {
      family: 'Univers 55 Roman',
      size: '1.5rem',
      sizeSmaller: '1.4rem',
      lineHeight: '2.5rem',
      lineHeightSmaller: '2rem',
    },
    link: {
      family: 'Univers 55 Roman',
      size: '1.5rem',
      sizeSmaller: '1.4rem',
      lineHeight: '2.5rem',
      lineHeightSmaller: '2rem',
    },
    badge: {
      family: 'Univers 55 Roman',
      size: '1.2rem',
      sizeSmaller: '1rem',
      lineHeight: '2.2rem',
      lineHeightSmaller: '1.8rem',
    },
  },
  layout: {
    header: {
      height: '6.4rem',
      heightSmaller: '4.8rem',
    },
    topMenuHeight: '3.2rem',
    topMenuHeightSmaller: '0rem',
    maxWidth: '78rem',
  },
};

export const getThemeColor = (color: ThemeColor) =>
  theme.colors[color] ?? color;

export const getThemeSpacing = (property: string) =>
  theme.spacing[property] ??
  (property === 'default' ? theme.grid.gutter : property ?? 'unset');

export { theme };
