import { GridProps } from './grid.props';

export const GridPresets: GridProps = {
  backgroundColor: 'unset',
  height: 'unset',
  width: 'unset',
  maxWidth: '100vw',
  gap: { lg: 's3', md: 's3', sm: 's1' },
  padding: { lg: 's3', md: 's3', sm: 's1' },
  flow: 'row',
};
