import { environment } from 'config/environment';
import { createLogger } from '@unly/utils-simple-logger';
import { EXTERNAL_SCRIPTS_FETCH_TIME } from 'constants/common';
import { User } from 'types/user.type';

const logger = createLogger({
  label: 'analytics',
});

export const loadAnalytics = () => {
  try {
    window.analytics.load(environment.SEGMENT_KEY);
  } catch (error) {
    logger.error(error);
  }
};

export const trackAnalyticsPageEvent = (url: string) => {
  try {
    window.analytics.page(url);
  } catch (error) {
    logger.error(error);
  }
};

export const getAnalyticsUserId = () => {
  try {
    return window.analytics.user().anonymousId();
  } catch (e) {
    return '';
  }
};

export const identifyAnalyticsUser = (user: User) => {
  if (user) {
    let userType = 'user';

    if (user.isAdmin) {
      userType = 'admin';
    } else if (user.isDesigner) {
      userType = 'designer';
    }

    window.analytics.identify(user.id, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      type: userType,
    });
  }
};

export const resetAnalyticsUser = () => {
  window.analytics.reset();
};

export const trackAnalyticsEvent = (event: string, properties?: any) => {
  try {
    window.analytics.track(event, properties);
  } catch (error) {
    logger.error(error);
  }
};

export interface AnalyticsPageData {
  title?: string;
  url?: string;
  path?: string;
  referrer?: string;
}

export const initializeAnalytics = url => {
  setTimeout(() => {
    loadAnalytics();
    trackAnalyticsPageEvent(url);
  }, EXTERNAL_SCRIPTS_FETCH_TIME);
};
