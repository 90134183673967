import React, { forwardRef, useCallback } from 'react';
import { TypographyProps } from './typography.props';
import { TypographyPresets } from './typography.presets';
import { StyledText } from './typography.styles';
import { Trans, useTranslation } from 'react-i18next';
import Link from 'next/link';
import { trackAnalyticsEvent } from 'utils/analytics';

const variantToType = {
  p1: 'p',
  p2: 'p',
  p3: 'p',
  badge: 'span',
};

export const Typography = forwardRef((props: TypographyProps, ref: any) => {
  const {
    type,
    variant,
    tx,
    txValues,
    text,
    htmlText,
    textStyle,
    color,
    href,
    hrefAs,
    passHref,
    target,
    prefetch,
    linkAnalyticsEvent,
    ...others
  } = Object.assign({}, TypographyPresets, props);

  const { t } = useTranslation();

  const onLinkClick = useCallback(() => {
    if (linkAnalyticsEvent) {
      trackAnalyticsEvent(linkAnalyticsEvent);
    }
  }, [linkAnalyticsEvent]);

  if (variant === 'link') {
    return (
      <Link
        href={href}
        as={hrefAs}
        // @ts-ignore
        ref={ref}
        passHref={passHref}
        prefetch={prefetch}>
        <a target={target} style={{ display: 'inline' }}>
          <StyledText
            variant={variant}
            textStyle={textStyle ?? 'bold'}
            color={color ?? 'primary'}
            onClick={onLinkClick}
            {...others}>
            {tx ? t(tx, txValues) : text}
          </StyledText>
        </a>
      </Link>
    );
  }

  if (htmlText) {
    return (
      <StyledText
        as={type ?? variantToType[variant || ''] ?? variant}
        variant={variant}
        ref={ref}
        textStyle={textStyle}
        color={color ?? 'black'}
        dangerouslySetInnerHTML={{ __html: htmlText }}
        {...others}
      />
    );
  }

  return (
    <StyledText
      as={type ?? variantToType[variant || ''] ?? variant}
      variant={variant}
      ref={ref}
      textStyle={textStyle}
      color={color ?? 'black'}
      {...others}>
      {!!tx ? (
        <Trans t={t} values={txValues}>
          {tx}
        </Trans>
      ) : (
        text
      )}
    </StyledText>
  );
});
