import React, { createContext, Dispatch, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';

export interface LayoutState {
  headerVisible?: boolean;
  headerVisibilityLocked?: boolean;
  footerVisible?: boolean;
  sideMenuVisible?: boolean;
  currentHeaderVisibility?: boolean;
  editingDesigner?: boolean;
  designerBackgroundColor?: string;
  designerHighlightColor?: string;
}

const initialState: LayoutState = {
  headerVisible: false,
  footerVisible: true,
  designerBackgroundColor: null,
  designerHighlightColor: null,
};

type Action =
  | { type: 'SET_PROPERTIES'; value: LayoutState }
  | { type: 'SET_HEADER_VISIBILITY'; value: boolean }
  | { type: 'SET_FOOTER_VISIBILITY'; value: boolean }
  | { type: 'SET_SIDE_MENU_VISIBILITY'; value: boolean }
  | { type: 'SET_HEADER_VISIBILITY_LOCKED'; value: boolean }
  | { type: 'SET_CURRENT_HEADER_VISIBILITY'; value: boolean }
  | { type: 'SET_DESIGNER_BACKGROUND_COLOR'; color: string }
  | { type: 'SET_DESIGNER_HIGHLIGHT_COLOR'; color: string }
  | { type: 'SET_EDITING_DESIGNER'; value: boolean }
  | { type: 'RESET_DESIGNER_THEME' };

function layoutReducer(draft: LayoutState, action: Action) {
  switch (action.type) {
    case 'SET_PROPERTIES':
      draft = Object.assign({}, draft, action.value);
      return draft;
    case 'SET_HEADER_VISIBILITY':
      draft.headerVisible = action.value;
      return draft;
    case 'SET_FOOTER_VISIBILITY':
      draft.footerVisible = action.value;
      return draft;
    case 'SET_SIDE_MENU_VISIBILITY':
      draft.sideMenuVisible = action.value;
      return draft;
    case 'SET_HEADER_VISIBILITY_LOCKED':
      draft.headerVisibilityLocked = action.value;
      return draft;
    case 'SET_CURRENT_HEADER_VISIBILITY':
      draft.currentHeaderVisibility = action.value;
      return draft;
    case 'SET_DESIGNER_BACKGROUND_COLOR':
      draft.designerBackgroundColor = action.color;
      return draft;
    case 'SET_DESIGNER_HIGHLIGHT_COLOR':
      draft.designerHighlightColor = action.color;
      return draft;
    case 'SET_EDITING_DESIGNER':
      draft.editingDesigner = action.value;
      return draft;
    case 'RESET_DESIGNER_THEME':
      draft.editingDesigner = false;
      draft.designerHighlightColor = null;
      draft.designerBackgroundColor = null;
      return draft;
  }
}

const LayoutStateContext = createContext<LayoutState>(initialState);

const LayoutDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const LayoutProvider = (props: {
  value?: LayoutState;
  children: React.ReactNode;
}) => {
  const { children, value } = props;

  const [state, dispatch] = useImmerReducer(
    layoutReducer,
    Object.assign({}, initialState, value),
  );

  useEffect(() => {
    if (value) {
      dispatch({ type: 'SET_PROPERTIES', value });
    }
  }, [value?.designerHighlightColor, value?.designerHighlightColor]);

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
};

export function useLayoutState(): LayoutState {
  const context = useContext(LayoutStateContext);

  if (context === undefined) {
    throw new Error(`useLayout must be used inside an LayoutProvider`);
  }

  return context;
}

export function useLayoutDispatch() {
  const context = useContext(LayoutDispatchContext);

  if (context === undefined) {
    throw new Error(`useLayout must be used inside an LayoutProvider`);
  }

  return context;
}

export const useLayout = () => {
  const state = useLayoutState();
  const dispatch = useLayoutDispatch();

  const setHeaderVisibility = (value: boolean): void => {
    dispatch({ type: 'SET_HEADER_VISIBILITY', value });
  };

  const setFooterVisibility = (value: boolean): void => {
    dispatch({ type: 'SET_FOOTER_VISIBILITY', value });
  };

  const hideSideMenu = (): void => {
    dispatch({ type: 'SET_SIDE_MENU_VISIBILITY', value: false });
  };

  const toggleSideMenuVisibility = (): void => {
    dispatch({
      type: 'SET_SIDE_MENU_VISIBILITY',
      value: !state.sideMenuVisible,
    });
  };

  const lockHeaderVisible = (): void => {
    dispatch({ type: 'SET_HEADER_VISIBILITY_LOCKED', value: true });
    dispatch({
      type: 'SET_CURRENT_HEADER_VISIBILITY',
      value: state.headerVisible,
    });
    dispatch({ type: 'SET_HEADER_VISIBILITY', value: true });
  };

  const unlockHeaderVisible = (): void => {
    dispatch({ type: 'SET_HEADER_VISIBILITY_LOCKED', value: false });
    dispatch({
      type: 'SET_HEADER_VISIBILITY',
      value: state.currentHeaderVisibility,
    });
  };

  const setEditingDesigner = (value: boolean): void => {
    dispatch({ type: 'SET_EDITING_DESIGNER', value });
  };

  const setDesignerBackgroundColor = (color: string): void => {
    dispatch({ type: 'SET_DESIGNER_BACKGROUND_COLOR', color });
  };

  const setDesignerHighlightColor = (color: string): void => {
    dispatch({ type: 'SET_DESIGNER_HIGHLIGHT_COLOR', color });
  };

  const resetDesignerTheme = (): void => {
    dispatch({ type: 'RESET_DESIGNER_THEME' });
  };

  return {
    ...state,
    setHeaderVisibility,
    setFooterVisibility,
    hideSideMenu,
    toggleSideMenuVisibility,
    lockHeaderVisible,
    unlockHeaderVisible,
    setDesignerBackgroundColor,
    setDesignerHighlightColor,
    setEditingDesigner,
    resetDesignerTheme,
  };
};
