import { BoxContainerProps } from './box-container.props';
import { BoxContainerPresets } from './box-container.presets';
import { StyledBoxContainer } from './box-container.styles';
import * as React from 'react';
import { forwardRef, memo } from 'react';

export const BoxContainer = memo(
  forwardRef((props: BoxContainerProps, ref: any) => {
    const elementProps = Object.assign({}, BoxContainerPresets, props);

    return <StyledBoxContainer ref={ref} {...elementProps} />;
  }),
);
