import { BoxContainerProps } from './box-container.props';

export const BoxContainerPresets: BoxContainerProps = {
  padding: 'unset',
  margin: 'unset',
  width: 'unset',
  height: 'unset',
  maxWidth: '100vw',
  overflow: 'unset',
};
